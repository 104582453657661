import React from 'react';
import { Global, css } from '@emotion/react';
import normalize from 'emotion-normalize';

import colors from './colors';
import { FONT_FAMILIES } from './typography';
import { MOBILE_PADDING } from './spacings';
import mq, { BREAKPOINTS } from './mediaQueries';
import { GLOBAL_STYLE_VARIABLES } from './variables';

import compose_font_size from 'utils/composeFontSize';

const fontFamilies = Object.keys(FONT_FAMILIES).join(',');

const mobileDesignBaseWidthPx = 320;

const globalStyles = css`
  ${normalize}

  html,
  body {
    margin: 0;
    padding: 0;
    min-height: 100%;
  }

  html {
    ${GLOBAL_STYLE_VARIABLES.fullScreenRatio}: 121.5;
    ${GLOBAL_STYLE_VARIABLES.baseRemRatio}: 8;

    font-family: ${fontFamilies}, sans-serif;
    font-size: calc(
      min(calc(9 / 16 * 100vw), 100vh) / var(${GLOBAL_STYLE_VARIABLES.fullScreenRatio})
    );
    scroll-behavior: smooth;

    &::after {
      position: fixed;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      z-index: -1;
      background: ${colors.backgroundDark};
      content: '';
    }

    ${mq.md} {
      ${GLOBAL_STYLE_VARIABLES.mobilePadding}: calc(
        (
            100vw -
              (
                ${mobileDesignBaseWidthPx} / var(${GLOBAL_STYLE_VARIABLES.baseRemRatio}) -
                  (((${MOBILE_PADDING} / var(${GLOBAL_STYLE_VARIABLES.baseRemRatio})) * 2))
              ) * 1rem
          ) / 2
      );

      ${GLOBAL_STYLE_VARIABLES.mobileRemRatio}: ${compose_font_size({
        scaleMax: 50,
        scaleMin: 100,
        priorityWidth: 600,
        widthMax: BREAKPOINTS.md.width,
        widthMin: BREAKPOINTS.sm.width,
      })};

      font-size: calc(
        ((100vw / ${mobileDesignBaseWidthPx}) * var(${GLOBAL_STYLE_VARIABLES.baseRemRatio})) +
          (
            var(${GLOBAL_STYLE_VARIABLES.mobileRemRatio}) -
              (var(${GLOBAL_STYLE_VARIABLES.baseRemRatio}) * 1px)
          )
      );
    }

    ${mq.sm} {
      ${GLOBAL_STYLE_VARIABLES.mobileRemRatio}: calc(var(${GLOBAL_STYLE_VARIABLES.baseRemRatio}) * 1px);
    }
  }

  body {
    ${GLOBAL_STYLE_VARIABLES.scrollbarWidth}: 0;
    ${GLOBAL_STYLE_VARIABLES.scrollbarCompensation}: calc(0 * 1px);

    &[data-scroll-lock] {
      ${GLOBAL_STYLE_VARIABLES.scrollbarCompensation}: var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth});

      padding-right: var(${GLOBAL_STYLE_VARIABLES.scrollbarCompensation});
      overflow: hidden;
    }
  }
`;

const GlobalStyle: React.FC = () => <Global styles={globalStyles} />;

export default GlobalStyle;

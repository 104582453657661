const makeBreakpoint = (width: number) => ({
  width,
  media: `@media (max-width: ${width}px)`,
});

export const BREAKPOINTS = {
  md: makeBreakpoint(959),
  sm: makeBreakpoint(480),
} as const;

const mediaQueries = {
  ...(Object.entries(BREAKPOINTS).reduce(
    (acm, [label, { width }]) => ({
      ...acm,
      [label]: `@media (max-width: ${width}px)`,
      [`${label}Min`]: `@media (min-width: ${width + 1}px)`,
    }),
    {}
  ) as Record<keyof typeof BREAKPOINTS, string> & Record<`${keyof typeof BREAKPOINTS}Min`, string>),
  use: (breakpoint: number) => `@media (max-width: ${breakpoint}px)`,
  useMin: (breakpoint: number) => `@media (min-width: ${breakpoint}px)`,
};

export default mediaQueries;

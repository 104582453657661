import { useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { animationSuspendedState } from 'animations/state/atoms';
import { getWindow } from 'utils/helpers';

const buffer = 1;
const unscrolledSuccessCount = 10;

const waitForScrollEnd = () =>
  new Promise<void>(resolve => {
    const check =
      ({
        lastScrollY = getWindow()?.scrollY ?? 0,
        lastScrollX = getWindow()?.scrollX ?? 0,
        unscrolledCount = 0,
      }: {
        lastScrollY?: number;
        lastScrollX?: number;
        unscrolledCount?: number;
      } = {}) =>
      () => {
        if (unscrolledCount >= unscrolledSuccessCount) {
          return resolve();
        }

        const { scrollY, scrollX } = window;

        const scrolledVertically = Math.abs(scrollY - lastScrollY) > buffer;
        const scrolledHorizontally = Math.abs(scrollX - lastScrollX) > buffer;

        const scrolled = scrolledVertically || scrolledHorizontally;

        requestAnimationFrame(
          check({
            lastScrollY: scrollY,
            lastScrollX: scrollX,
            unscrolledCount: scrolled ? 0 : unscrolledCount + 1,
          })
        );
      };

    requestAnimationFrame(check());
  });

export const useAnimationScrollSuspend = () => {
  const setAnimationSuspendedState = useSetRecoilState(animationSuspendedState);

  useLayoutEffect(() => {
    setAnimationSuspendedState(true);

    waitForScrollEnd().then(() => {
      setAnimationSuspendedState(false);
    });

    return () => {
      setAnimationSuspendedState(true);
    };
  }, []);
};

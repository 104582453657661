import GlobalStyle from './GlobalStyle';
import colors from './colors';
import depths from './depths';
import durations from './durations';
import mediaQueries from './mediaQueries';
import spacings from './spacings';
import typography from './typography';
import { GLOBAL_STYLE_VARIABLES } from './variables';
import utils from './utils';

const theme = {
  colors,
  depths,
  durations,
  mq: mediaQueries,
  spacings,
  tp: typography,
  utils,
};

export { theme as default, GlobalStyle, GLOBAL_STYLE_VARIABLES };

import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import { useQueryParams } from './hooks';
import StateProvider from './StateProvider';
import ThemeProvider from './ThemeProvider';

import { AnimationStateProvider } from 'animations';

const Page: GatsbyBrowser['wrapPageElement'] = ({ element, props: pageProps }) => {
  useQueryParams(pageProps);

  return (
    <StateProvider>
      <AnimationStateProvider>
        <ThemeProvider>{element}</ThemeProvider>
      </AnimationStateProvider>
    </StateProvider>
  );
};

export default Page;

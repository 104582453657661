import { css } from '@emotion/react';

export const CUSTOM_FONTS = ['Nimbus', 'Switzer'];
export const DEFAULT_FONTS = ['Switzer'];

export const FONT_FAMILIES = {
  Inter: {
    light: '300',
    regular: '400',
    lightBold: '500',
    semiBold: '600',
    bold: '700',
    veryBold: '800',
  },

  Poppins: {
    semiBold: '600',
    bold: '700',
  },

  Montserrat: {
    regular: '400',
    lightBold: '500',
    semiBold: '600',
    bold: '700',
  },

  'Nunito Sans': {
    regular: '400',
    semiBold: '600',
    bold: '700',
    veryBold: '800',
    heavy: '900',
  },

  Nimbus: {
    regular: '400',
    bold: '700',
  },

  Switzer: {
    thin: '100',
    lightest: '200',
    light: '300',
    regular: '400',
    lightBold: '500',
    semiBold: '600',
    bold: '700',
    veryBold: '800',
    black: '900',
  },
} as const;

const typography = {
  useFont: (fontCallback: (fonts: typeof FONT_FAMILIES) => string) =>
    fontCallback(
      Object.entries(FONT_FAMILIES).reduce((familyAcm, [family, weights]) => {
        return {
          ...familyAcm,
          [family]: Object.entries(weights).reduce((weightAcm, [key, weight]) => {
            return {
              ...weightAcm,
              [key]: css`
                font-family: ${family};
                font-weight: ${weight};

                ${family === 'Nimbus'
                  ? css`
                      translate: -0.04em 18%;

                      @supports (font-format(opentype)) {
                        translate: -0.04em -2%;
                      }
                    `
                  : ''}
              `,
            };
          }, {}),
        };
      }, {} as typeof FONT_FAMILIES)
    ),
  fontWeightsByFamily: FONT_FAMILIES,
};

export default typography;

import { useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';

export const useDebounce = (callback, timeout = 500) => {
  const ref = useRef<typeof callback>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, timeout);
  }, []);
};

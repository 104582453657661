const colors = {
  black: '#000000',
  white: '#FFFFFF',
  backgroundLight: '#F2F2F2',
  backgroundLightest: '#EDEDED',
  backgroundDark: '#1F1F1F',
  backgroundLightDark: '#2d2d2d',
  offWhite: '#E6E6E6',
  lightGray: '#9B9B9B',
  lighterGray: '#F0F0F0',
  lightestGray: '#F9F9F9',
  contentText: '#000000CC',
  silverGray: '#464646',
  textGray: '#898989',
  textDark: '#2B2B2B',
  borderBackground: '#E2E2E2',
  borderBackgroundDark: '#B5B5B5',
  backgroundLightBlue: '#F4FAFF',
  button: '#004C92',
  primary: '#F3C348',
  primaryDark: '#F3A318',
};

export default colors;

import { GLOBAL_STYLE_VARIABLES } from './variables';

export const DESKTOP_MAX_WIDTH = 1140;
export const MOBILE_PADDING = 16;

const spacings = {
  padding: `var(${GLOBAL_STYLE_VARIABLES.mobilePadding})`,
};

export default spacings;

import { useRecoilValue } from 'recoil';

import { AnimationEnabledBreakpoints, useAnimationEnabled } from './useAnimationEnabled';

import { animationRefreshState, animationSuspendedState } from 'animations/state/atoms';

export const useAnimationState = (breakpoint: AnimationEnabledBreakpoints = 0) => {
  const refreshed = useRecoilValue(animationRefreshState);
  const suspended = useRecoilValue(animationSuspendedState);
  const enabled = useAnimationEnabled(breakpoint);

  return { refreshed, suspended, enabled };
};

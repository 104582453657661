export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export enum LANGUAGE_LOCALES {
  ENGLISH = 'en-gb',
  LITHUANIAN = 'lt',
  POLISH = 'pl',
}

export const BASE_LANGUAGE = LANGUAGE_LOCALES.ENGLISH;

export enum QUERY_PARAMS {
  TOKEN = 'token',
  CODE = 'code',
  WEB_PLAN_ID = 'web_plan_id',
  COUPON = 'coupon',
  LICENSE_ID = 'license_id',
  GCLID = 'gclid',
  FBCLID = 'fbclid',
  TUNE_OFFER_ID = 'tune_offer_id',
  TUNE_TRANSACTION_ID = 'tune_transaction_id',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  IMPACT_CLICK_ID = 'irclickid',
}

export enum INTERNAL_LINKS {
  HOME = '/',
  BLOG = '/blog',
}

export enum EXTERNAL_LINKS {
  LOGIN = 'https://player.shakespearemusic.com',
}

export const SHAKESPEARE_MUSIC_APP_DOMAIN = '.shakespearemusic.com';

import React, { useRef } from 'react';

export const useRefArray = <T>() => {
  const refs = useRef<Record<string, React.RefObject<T>>>({});

  const refValues = Object.values(refs.current);

  const getRef: (key: string) => React.LegacyRef<T> = key => element => {
    if (!refs.current[key]) {
      refs.current[key] = { current: element };
    }
  };

  return [refValues, getRef] as const;
};

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shakespeare Music","short_name":"Shakespeare Music","start_url":"/","display":"standalone","icon":"src/assets/others/logo.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"de78d70b9a75d8295019d1546904e11f"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"custom":[{"name":["Nimbus"],"file":"/fonts/Nimbus Sans L/font.css"},{"name":["Switzer"],"file":"/fonts/Switzer/font.css"}],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"},{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap"},{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"},{"name":"Nunito Sans","file":"https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KL627LH","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"shakespearemusic","accessToken":"MC5aRjlSaHhFQUFDVUFtVnN2.77-977-9PWcQWHHvv70l77-9cTxEMu-_vQkX77-977-9A--_ve-_ve-_ve-_ve-_vQnvv70EIe-_ve-_vVg","promptForAccessToken":true,"apiEndpoint":"https://shakespearemusic.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { useLayoutEffect, EffectCallback, DependencyList } from 'react';
import { useRecoilValue } from 'recoil';
import { gsap } from 'gsap';

import { AnimationEnabledBreakpoints, useAnimationEnabled } from './useAnimationEnabled';

import { animationRefreshState, animationSuspendedState } from 'animations/state/atoms';

export const useAnimationEffect = (
  callback: (state: { suspended: boolean; enabled: boolean }) => ReturnType<EffectCallback>,
  deps: DependencyList | undefined = [],
  enabledBreakpoint: AnimationEnabledBreakpoints = 0
) => {
  const refreshed = useRecoilValue(animationRefreshState);
  const suspended = useRecoilValue(animationSuspendedState);
  const enabled = useAnimationEnabled(enabledBreakpoint);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      callback({ suspended, enabled });
    });

    return () => context.revert();
  }, [...deps, callback, refreshed, suspended, enabled]);
};

import React, { PropsWithChildren } from 'react';
import { componentResolverFromMap, PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

import linkResolver from 'utils/linkResolver';

const PreviewProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({}),
        },
      ]}
    >
      {children}
    </PrismicPreviewProvider>
  );
};

export default PreviewProvider;

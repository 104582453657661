import { LinkResolverFunction } from '@prismicio/helpers';
import type { FilledContentRelationshipField } from '@prismicio/types';

import archivesMap from './archivesMap.json';

const BASE_LANGUAGE = 'en-gb';

const linkResolver: LinkResolverFunction = ({
  lang,
  uid,
  type,
}: FilledContentRelationshipField) => {
  if (archivesMap?.[lang]?.[type]) return `/${archivesMap[lang][type]}${uid}`;

  if (lang !== BASE_LANGUAGE) return `/${lang}/${uid ?? ''}`;

  return `/${uid ?? ''}`;
};

export default linkResolver;

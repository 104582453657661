export const isBrowser = typeof window !== 'undefined';

export const filterProps = (...propNames: string[]) => ({
  shouldForwardProp: propName => propNames.indexOf(propName) < 0,
});

export const capitalize = (string: string) =>
  string.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function (letter) {
    return letter.toUpperCase();
  });

export const getWindow = () => (typeof window !== 'undefined' ? window : null);

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/Author/Page.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-blog-archive-page-tsx": () => import("./../../../src/templates/BlogArchive/Page.tsx" /* webpackChunkName: "component---src-templates-blog-archive-page-tsx" */),
  "component---src-templates-blog-article-page-tsx": () => import("./../../../src/templates/BlogArticle/Page.tsx" /* webpackChunkName: "component---src-templates-blog-article-page-tsx" */),
  "component---src-templates-discover-page-tsx": () => import("./../../../src/templates/Discover/Page.tsx" /* webpackChunkName: "component---src-templates-discover-page-tsx" */),
  "component---src-templates-european-union-projects-page-tsx": () => import("./../../../src/templates/EuropeanUnionProjects/Page.tsx" /* webpackChunkName: "component---src-templates-european-union-projects-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/Home/Page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-industry-page-tsx": () => import("./../../../src/templates/Industry/Page.tsx" /* webpackChunkName: "component---src-templates-industry-page-tsx" */),
  "component---src-templates-privacy-policy-page-tsx": () => import("./../../../src/templates/PrivacyPolicy/Page.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-tsx" */),
  "component---src-templates-solution-page-tsx": () => import("./../../../src/templates/Solution/Page.tsx" /* webpackChunkName: "component---src-templates-solution-page-tsx" */)
}


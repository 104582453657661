import { atom } from 'recoil';

export const animationRefreshState = atom({
  key: 'animationRefresh',
  default: 0,
});

export const animationSuspendedState = atom({
  key: 'animationSuspended',
  default: true,
});

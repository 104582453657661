import { css } from '@emotion/react';

import { GLOBAL_STYLE_VARIABLES } from './variables';

const utils = {
  rem: (unitLength: number): string =>
    `calc(${unitLength} / var(${GLOBAL_STYLE_VARIABLES.baseRemRatio}) * 1rem)`,
  hideScroll: css`
    ${'scrollbar-width'}: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
};

export default utils;
